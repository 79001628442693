<template>
  <div>
    <b-card no-body class="mb-0 table-no-top table-text-nowrap">
      <b-table
        ref="refAccountListTable"
        class="position-relative mb-25"
        :items.sync="fetchAccounts"
        responsive
        :fields="tableColumnsStudentView"
        :filter.sync="searchQuery"
        :filterByFormatted="true"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        @row-contextmenu="onRowContextMenu"
      >
        <!-- Column: Student Full Name -->
        <template #cell(student.fullName)="data">
          <b-link
            :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
            class="font-weight-bold text-nowrap text-white mb-0"
            style="font-size: 1.25rem; line-height: 1.75rem"
          >
            {{ data.item.student.fullName }}
          </b-link>
          <b-link
            :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
            class="d-flex align-items-center text-muted"
          >
            <small style="font-size: 1.05rem; line-height: 1.35rem">{{ data.item.student.myIpId }}</small>
          </b-link>
        </template>

        <!-- Column: Cohort -->
        <template #cell(cohort)="data">
          <div class="text-nowrap" v-if="data.item.cohort">
            <div
              v-if="data.item.cohort.session.type === 'pathway' && data.item.cohort.session.pathway"
              class="d-flex align-items-center text-nowrap"
              style="gap: 0.625rem"
            >
              <b-avatar
                size="36"
                :src="data.item.cohort.session.pathway.avatar"
                :text="avatarText(data.item.cohort.session.pathway.name)"
                :variant="`light-info`"
                :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
                style="margin-top: 2px"
                rounded
              />
              <div class="d-flex flex-column" style="gap: 0">
                <b-link
                  :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
                  class="font-weight-bold text-nowrap text-white mb-0"
                >
                  {{ resolvePathwayCohortName(data.item.cohort.session) }}
                </b-link>
                <b-link
                  :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
                  class="d-flex align-items-center text-muted text-nowrap"
                >
                  <lucide-icon name="Library" style="width: 14px; height: 14px; margin-right: 1px" />
                  <small> {{ data.item.cohort.session.pathway.name }}</small>
                </b-link>
              </div>
            </div>
            <div
              v-if="data.item.cohort.session.type === 'course' && data.item.cohort.session.course"
              class="d-flex align-items-center text-nowrap"
              style="gap: 0.625rem"
            >
              <b-avatar
                size="36"
                :src="data.item.cohort.session.course.avatar"
                :text="avatarText(data.item.cohort.session.course.name)"
                :variant="`light-info`"
                :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
                rounded
              />
              <div class="d-flex flex-column" style="gap: 0">
                <b-link
                  :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
                  class="font-weight-bold text-nowrap text-white mb-0"
                >
                  {{ resolveCourseSectionName(data.item.cohort.session) }}
                </b-link>
                <b-link
                  :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
                  class="d-flex align-items-center text-muted text-nowrap"
                >
                  <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 1px" />
                  <small> {{ data.item.cohort.session.course.name }}</small>
                </b-link>
              </div>
            </div>
          </div>
          <div class="text-nowrap" v-else>
            <feather-icon
              :id="`account-row-${data.item.id}-set-cohort-icon`"
              icon="PlusCircleIcon"
              class="cursor-pointer"
              size="16"
              @click="startCohortSelect(data.item)"
            />
            <b-tooltip title="Assign Cohort" class="cursor-pointer" :target="`account-row-${data.item.id}-set-cohort-icon`" />
            Unassigned
          </div>
        </template>

        <!-- Column: Attributes - Program -->
        <template #cell(profile.attributes.fundingSource)="data">
          <b-badge
            v-if="data.item.profile"
            :variant="`light-${resolveAccountProgramVariant(data.item.profile.attributes.fundingSource)}`"
            class="text-nowrap text-capitalize"
          >
            {{ title(data.item.profile.attributes.fundingSource) }}
          </b-badge>
          <div v-else class="text-nowrap">Unassigned</div>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveAccountStatusVariant(data.item.status)}`" class="text-nowrap text-capitalize">
            {{ title(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: total -->
        <template #cell(total)="data">
          <span class="text-nowrap">{{ money(data.item.total) }} ({{ data.item.charges.length }})</span>
        </template>

        <!-- Column: balance -->
        <template #cell(totalPaid)="data">
          <span class="text-nowrap"
            >{{ money(data.item.totalPaid) }} ({{ data.item.payments.filter((a) => a.status === 'paid').length }})</span
          >
        </template>

        <!-- Column: balance -->
        <template #cell(balance)="data">
          <span class="text-nowrap">{{ money(data.item.balance) }}</span>
        </template>

        <!-- Column: Cohort -->
        <template #cell(createdAt)="data">
          {{ moment(data.item.createdAt).format('L') }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item
              v-if="$can('accounting.receivable.account.get')"
              @click="$router.push({ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } })"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-75 mt-0">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                size="s"
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAccounts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <vue-context ref="studentArCohortListMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a
          class="d-flex align-items-center justify-content-between"
          v-if="$can('accounting.receivable.account.get')"
          @click="$router.push({ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } })"
        >
          <span>View</span>
          <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import rosterStoreModule from '../../../../../store/roster/rosterStoreModule';
import VueContext from 'vue-context';
import FormattedTime from '@/@core/components/time/FormattedTime.vue';
import useProfiles from '@/views/apps/roster/profile/useProfiles';
import useUsersList from '@/views/apps/audit/audit-list/useAuditList';
import usePathways from '@/views/apps/roster/pathways/usePathways';
import useCourses from '@/views/apps/roster/courses/useCourses';
import { avatarText, title } from '@core/utils/filter';
import usePathwayCohorts from '@/views/apps/roster/pathways/cohorts/usePathwayCohorts';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';
import moment from 'moment';
import useAccountsList from '@/views/apps/accounting/receivable/account/list/useAccountsList';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    FormattedTime,

    vSelect,
    VueContext,
  },
  props: {
    studentId: {
      type: String,
      required: true,
    },
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },
    onRowContextMenu(item, index, event) {
      this.$refs.studentArCohortListMenu.open(event, { item });
      event.preventDefault();
    },
  },
  setup(props) {
    const createClassActive = ref(false);
    const editClassActive = ref(false);
    const editClassData = ref({});

    const { resolveUserRoleVariant } = useUsersList();
    const { resolvePathwayStatusVariant } = usePathways();
    const { resolveCourseStatusVariant } = useCourses();

    const { resolvePathwayCohortName } = usePathwayCohorts();
    const { resolveCourseSectionName } = useCourseSections();

    const {
      fetchAccounts,
      tableColumnsStudentView,
      perPage,
      currentPage,
      totalPageCount,
      totalAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAccountListTable,
      refetchData,

      // UI

      resolveAccountStatusVariant,
      resolveAccountProgramVariant,
      resolveAccountYNVariant,
      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,
      balanceFilter,

      createAtStartFilter,
      createAtEndFilter,

      studentFilter,

      //
      paymentDateFilter,
    } = useAccountsList();

    studentFilter.value = props.studentId;

    return {
      fetchAccounts,
      tableColumnsStudentView,
      perPage,
      currentPage,
      totalPageCount,
      totalAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAccountListTable,
      refetchData,

      // Filter
      avatarText,
      title,

      // UI
      resolveAccountStatusVariant,
      resolveAccountProgramVariant,
      resolveAccountYNVariant,

      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,

      createAtStartFilter,
      createAtEndFilter,
      balanceFilter,
      //
      paymentDateFilter,

      resolvePathwayCohortName,
      resolveCourseSectionName,

      //
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
